const defaultSettings = {
    sourceApp: 'Platform',
    timeExpiration: 60*60,
    extraFields: [
        { name: 'lastname', label: 'Last Name', 'isRequired': true },
        { name: 'entry_term', 'type': 'dropdown', 'label': 'Entry Term', 'options': ['Fall 2021', 'Spring 2022', 'Fall 2022', 'Spring 2023', 'Fall 2023']},
        { name: 'student_type', 'type': 'dropdown', 'label': 'Student Type', 'options': ['First Year', 'Transfer']},
    ],
    headerText: null,
    formButtonText: 'SUBMIT & CONTINUE',
    formCancelText: 'No, thanks',
    hidePhoneField: true,
    redirectAfterCloseNC: true,
    formLabels: {
        name: 'First Name',
        phone: 'Home Phone'
    }
}

function mergeConfig(defaultSettings, userSettings) {
    if (!userSettings) {
        return defaultSettings
    }

    const settings = {...defaultSettings, ...userSettings}

    if (userSettings.extraFields) {
        const extraFields = []
        for (const field of defaultSettings.extraFields) {
            if (!userSettings.extraFields.find(x => x.name === field.name)) {
                extraFields.push(field)
            }
        }
        settings.extraFields = extraFields.concat(userSettings.extraFields)
    } else {
        settings.extraFields = defaultSettings.extraFields
    }

    return settings
}

export {
    defaultSettings,
    mergeConfig
}