import { defaultSettings, mergeConfig } from './config'

let TRIGGER_TYPE_CTA = 0 // CTA clicked
let TRIGGER_TYPE_TIME = 1 // CTA clicked

let rules = []

function addRule(promotion, trigger, name) {
    rules.push({
        name: name ? name : promotion.name,
        type: trigger.type,
        headerText: promotion.title,
        value: trigger.value
        //  promoHtml: promotion.description
    })
}

function getPopupOpen(customF) {
    return function ($m, rule) {       
        $m.querySelectorAll('input').forEach(e => e.addEventListener('click', function () {
            this.focus()
        }))
        
        const inputName = document.querySelector('.sbnc-row-name')
        const inputLastName = document.querySelector('.sbnc-form-lastname')
        if (inputName && inputLastName) { inputName.after(inputLastName) }

        if (customF) {
            customF()
        }
    }
}

function getMatomoVisitorId(matomoId) {
    if (window.Matomo) {
        let tracker = window.Matomo.getTracker('//analytics.studentbridge.com/analytics/piwik.php', matomoId)
        return tracker.getVisitorId()
    } else {
        return ''
    }
}

function initPromotions(promotions, cfg, info, ctas, piwikId, customF) {
    // Creating Namecapture form rules according to current site settings
    promotions.forEach(function (promotion) {
        promotion.triggers.forEach(function (trigger) {
            let triggerType = trigger.type;
            if (triggerType === TRIGGER_TYPE_CTA) {
                ctas.forEach(function (cta) {
                    trigger.type = window.sbPromotion.RULE_TYPE_TRIGGER
                    addRule(promotion, trigger, cta.name)
                })
            } else if (triggerType === TRIGGER_TYPE_TIME) {
                trigger.type = window.sbPromotion.RULE_TYPE_TIME
                addRule(promotion, trigger)
            }
        })
    })

    const dynamicSettings = {
        url: cfg.post_url,
        headerImage: info.logo,
        headerImageAlt: info.logo.title,
        onPopupOpen: getPopupOpen(customF),
    }

    const settings = {...mergeConfig(defaultSettings, cfg.settings), ...dynamicSettings}

    let extraFields = [
        { name: 'visitor_id', hidden: true, value: () => getMatomoVisitorId(piwikId) },
        { name: 'piwik_id', hidden: true, value: piwikId }
    ]

    settings.extraFields = settings.extraFields.concat(extraFields)
    settings.rules = rules.concat(settings.rules || [])

    // Namecapture library initialization
    let promo = window.sbPromotion(settings)

    // Activate Namecapture promotion triggers on CTA button clicks 
    document.querySelectorAll('.cta-popup-trigger').forEach(
        btn => btn.addEventListener('click', (function (e) {
            e.preventDefault()

            let btn = e.target
            let ctaName = btn.dataset.cta
            let ctaLink = btn.getAttribute('href')
            let state = promo.notify(ctaName)

            if (state) {
                state.on('submit', function () {
                    window.open(ctaLink)
                })
                state.on('close', function () {
                    if (settings.redirectAfterCloseNC) {
                        window.open(ctaLink)
                    }
                })
            } else {
                window.open(ctaLink)
            }

        })
    ))
}
window.initPromotions = initPromotions
